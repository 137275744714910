/** @format */

import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const data = [
  {
    id: 1,
    head: "What Is Checkmate?",
    para: "Check Mate is a Binance Smart Chain (BSC) project offering top-notch utilities such as staking, governance, farming, and more.    ",
  },
  {
    id: 2,
    head: "Any locking period on staking?",
    para: "Initially, there won't be a staking locking period. However, as we introduce multiple pools, some of them may offer the highest APY but come with a locking period.",
  },
  {
    id: 3,
    head: "What sustains the demand for CKM token?",
    para: "$CKM is the native token for CheckMate. It is essential for engaging in the staking pool and enjoying lifelong rewards. Consequently, the token's demand is exclusively driven by the intrinsic value it delivers.  ",
  },
  {
    id: 4,
    head: "How the price sustain with the this much Rewards?",
    para: "The team's competence, adherence to a robust roadmap, extensive marketing efforts, and periodic buybacks and token burns will collectively contribute to maintaining and sustaining the price.",
  },
  {
    id: 5,
    head: "Where can the CKM token purchased?",
    para: "The token is not yet available; stay updated on the latest developments by following our official Twitter and Telegram channels.",
  },
];

const Faqs = () => {
  const [showMore, setShowMore] = useState(-1);

  const toggleShowMore = index => {
    console.log(index);
    if (showMore === index) {
      setShowMore(-1);
    } else {
      setShowMore(index);
    }
  };

  return (
    <>
      <div className='questions_sec'>
        <div className='container'>
        <div className='head-area'>
          <h1>FAQs</h1>
          <p className="sm:w-100 w-96 m-auto">
          Frequently Asked Questions
          </p>
          <hr className='m-auto mt-3' />
        </div>

          <div className='row mt-5'>
            {data.map((res, index) => {
              return (
                <div className='col-md-6 ' key={res.id}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h5>{res.head}</h5>

                    {showMore === index ? (
                      <AiOutlineMinus
                        className='icon'
                        onClick={() => toggleShowMore(index)}
                        size={22}
                      />
                    ) : (
                      <AiOutlinePlus
                        className='icon'
                        onClick={() => toggleShowMore(index)}
                        size={22}
                      />
                    )}
                  </div>
                  <div className='mt-3'>
                    {showMore === index ? <p>{res.para}</p> : null}
                  </div>
                  <hr className='' />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
