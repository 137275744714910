/** @format */

import React from "react";

const Partners = () => {
  return (
    <div className=' py-24 sm:py-20'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='head-area'>
          <h1>Trusted Partners</h1>

          <hr className='m-auto mt-3' />
        </div>

        <div className='mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5'>
          <img
            className='col-span-2 max-h-12 w-full object-contain lg:col-span-1'
            src='/assets/trust-7.png'
            alt=''
            width={158}
            height={48}
          />
          <img
            className='col-span-2 max-h-12 w-full object-contain lg:col-span-1'
            src='assets/dexview.svg'
            alt=''
            width={158}
            height={48}
          />
          <img
            className='col-span-2 max-h-12 w-full object-contain lg:col-span-1'
            src='assets/PancakeSwa.png'
            alt=''
            width={158}
            height={48}
          />
          <img
            className='col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1'
            src='assets/bsc.png'
            alt=''
            width={158}
            height={48}
          />
          <img
            className='col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1'
            src='assets/cg-logo.png'
            alt=''
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>
  );
};

export default Partners;
