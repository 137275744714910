/** @format */

import React from "react";
import Chart from "./Chart";

function Tokenomics() {
  return (
    <div
      className='container-fluid tokenomics mt-5  bg-[#101010] py-5'
      id='tokenomics'>
      <div className='container'>
        <div className='head-area'>
          <h1>Tokenomics</h1>
          <p className='sm:w-100 m-auto'>CKM Token Allocation</p>
          <hr className='m-auto mt-3' />
        </div>
        <div className='row mt-5'>
          <div className='col-md-4 mt-4'>
            <div className=''>
              <p className='page6'>
                Private Sale <span style={{ color: "#f8c100" }}>5%</span>
              </p>
              <div className='progress'>
                <div
                  className='progress-bar progress-bar-striped progress-bar-animated'
                  role='progressbar'
                  style={{ width: "5%" }}
                  aria-valuenow='5'
                  aria-valuemin='0'
                  aria-valuemax='100'></div>
              </div>
              <p className='page6'>
                Fair launch <span style={{ color: "#f8c100" }}>30%</span>
              </p>
              <div className='progress'>
                <div
                  className='progress-bar bar-2  progress-bar-striped progress-bar-animated'
                  role='progressbar'
                  style={{ width: "30%" }}
                  aria-valuenow='30'
                  aria-valuemin='0'
                  aria-valuemax='100'></div>
              </div>
              <p className='page6'>
                LP <span style={{ color: "#f8c100" }}>16%</span>
              </p>
              <div className='progress'>
                <div
                  className='progress-bar bar-3  progress-bar-striped progress-bar-animated'
                  role='progressbar'
                  style={{ width: "16%" }}
                  aria-valuenow='16'
                  aria-valuemin='0'
                  aria-valuemax='100'></div>
              </div>
              <p className='page6'>
                Staking rewards <span style={{ color: "#f8c100" }}>14%</span>
              </p>
              <div className='progress'>
                <div
                  className='progress-bar bar-4  progress-bar-striped progress-bar-animated'
                  role='progressbar'
                  style={{ width: "14%" }}
                  aria-valuenow='14'
                  aria-valuemin='0'
                  aria-valuemax='100'></div>
              </div>
              <p className='page6'>
                P2E rewards <span style={{ color: "#f8c100" }}>10%</span>
              </p>
              <div className='progress'>
                <div
                  className='progress-bar bar-5  progress-bar-striped progress-bar-animated'
                  role='progressbar'
                  style={{ width: "10%" }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'></div>
              </div>

              <p className='page6'>
                Airdrop rewards <span style={{ color: "#f8c100" }}>5%</span>
              </p>
              <div className='progress'>
                <div
                  className='progress-bar bar-6  progress-bar-striped progress-bar-animated'
                  role='progressbar'
                  style={{ width: "5%" }}
                  aria-valuenow='5'
                  aria-valuemin='0'
                  aria-valuemax='100'></div>
              </div>

              <p className='page6'>
                CEX Listing<span style={{ color: "#f8c100" }}>10%</span>
              </p>
              <div className='progress'>
                <div
                  className='progress-bar bar-7  progress-bar-striped progress-bar-animated'
                  role='progressbar'
                  style={{ width: "10%" }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'></div>
              </div>


              <p className='page6'>
              Insurance Funds<span style={{ color: "#f8c100" }}>5%</span>
              </p>
              <div className='progress'>
                <div
                  className='progress-bar bar-7  progress-bar-striped progress-bar-animated'
                  role='progressbar'
                  style={{ width: "5%" }}
                  aria-valuenow='5'
                  aria-valuemin='0'
                  aria-valuemax='100'></div>
              </div>

              <p className='page6'>
              Marketing <span style={{ color: "#f8c100" }}>10%</span>
              </p>
              <div className='progress'>
                <div
                  className='progress-bar bar-7  progress-bar-striped progress-bar-animated'
                  role='progressbar'
                  style={{ width: "10%" }}
                  aria-valuenow='10'
                  aria-valuemin='0'
                  aria-valuemax='100'></div>
              </div>

              <p className='page6'>
              Team<span style={{ color: "#f8c100" }}>2%</span>
              </p>
              <div className='progress'>
                <div
                  className='progress-bar bar-7  progress-bar-striped progress-bar-animated'
                  role='progressbar'
                  style={{ width: "2%" }}
                  aria-valuenow='2'
                  aria-valuemin='0'
                  aria-valuemax='100'></div>
              </div>

              <p className='page6'>
              Development Funds<span style={{ color: "#f8c100" }}>3%</span>
              </p>
              <div className='progress'>
                <div
                  className='progress-bar bar-7  progress-bar-striped progress-bar-animated'
                  role='progressbar'
                  style={{ width: "3%" }}
                  aria-valuenow='3'
                  aria-valuemin='0'
                  aria-valuemax='100'></div>
              </div>

            </div>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-7 m-auto '>
            <div className='box'>
              <Chart />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tokenomics;
