/** @format */

import React from "react";

const About = () => {
  return (
    <div className='container-fluid mt-5  bg-[#101010] pb-5 '>
      <div className='container pt-5 '>
        <div className='head-area animate-appear-slide-up'>
          <h1>what is CheckMate?</h1>
          <p className='sm:w-100 w-96 m-auto'> </p>
          <hr className='m-auto mt-3' />
        </div>
        <div className='row about pt-5 animate-appear-slide-up'>
          <p className='m-auto '>
            Check Mate represents an innovative initiative functioning within
            the Binance Smart Chain (BSC) network, with a mission to transform
            the dynamics of transactions and engagements in the realm of
            decentralized finance (DeFi). Harnessing the capabilities of
            blockchain technology, Check Mate is committed to furnishing users
            with a safe, seamless, and intuitive interface for a diverse range
            of financial endeavors. By prioritizing inclusively, accountability,
            and dependability, Check Mate endeavors to establish itself as a
            pivotal contender in the ever-expanding domain of decentralized
            finance, offering users the added advantage of substantial and
            profitable returns.
          </p>
          <p className='m-auto mt-5'>
            Tired of falling victim to rug projects? Look no further, as Check
            Mate has arrived to change the game. With our unwavering commitment
            to transparency and security, we ensure that your investments remain
            safe and your trust in the decentralized finance (DeFi) space is
            restored. Say goodbye to the fears of rug pulls and malicious
            schemes, as we pave the way for a new era of reliability and
            accountability. Let Check Mate be your shield in the volatile world
            of cryptocurrencies, enabling you to invest with confidence and
            peace of mind.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
