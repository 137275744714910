/** @format */

import React from "react";
import { TiTick } from "react-icons/ti";
import { ImRadioUnchecked } from "react-icons/im";
import { IoEllipsisHorizontalCircle } from "react-icons/io5";



function Roadmap() {
  return (
    <div className='container-fluid  roadmap-bg mt-5' id='roadmap'>
      <div className='container'>
        <div className='row head-area'>
          <div className='col-md-12 '>
            <h1 data-aos='fade-down' data-aos-delay='300'>
              ROADMAP
            </h1>
            <p data-aos='fade-up' data-aos-delay='500'>
              A road down the adventures of Check Mate
            </p>
            <hr className='m-auto mt-3' />
          </div>
        </div>

        <div className='row mt-5'>
          <div className='col-md-3'>
            <div
              className='roadmap-box'
              data-aos='fade-right'
              data-aos-delay='100'>
              <div>
                <h1>Q4.2 (2023)</h1>
                <TiTick className='tick' />
              </div>
              <p>Completed</p>

              <ul className='list-unstyled'>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Idea development
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Team Building{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Points Mechanism
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Website development
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Documentation{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Social Media Presence{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Market Analysis{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Smart Contract Creation{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Airdrop{" "}
                </li>
              </ul>
            </div>
          </div>

          <div className='col-md-3'>
            <img src={"assets/Vector.svg"} alt='' className='mb-4' />
            <div
              className='roadmap-box'
              data-aos='fade-up'
              data-aos-delay='300'>
              <div>
                <h1>Q1.1 </h1>
                {/* <TiTick className='tick' /> */}
                <IoEllipsisHorizontalCircle className='progresss' />
              </div>
              <span>(2024)</span>

              <ul className='list-unstyled'>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Cross chain Interoperability{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  NFTs minting{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Marketplace{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  P2E chess game beta version{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Farming main net{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Tier2 CEX listing{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Strategic Partnerships{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Buy back{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Mobile app development{" "}
                </li>
              </ul>
            </div>
          </div>
          <div className='col-md-3'>
            <div
              className='roadmap-box'
              data-aos='fade-down'
              data-aos-delay='500'>
              <div>
                <h1>Q1.2 (2024)</h1>
                {/* <TiTick className='tick' /> */}
                <ImRadioUnchecked />
              </div>
              <span>(2024)</span>

              <ul className='list-unstyled'>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  P2E game{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  IDO launchpad for new tokens{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  DEX trade anytime anywhere{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Gaming events{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Tier1 CEX listing{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Research & Development{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Multichain staking{" "}
                </li>
              </ul>
            </div>

            <img
              src={"assets/Vector1.svg"}
              alt=''
              className='mt-4'
              style={{ float: "right" }}
            />
          </div>
          <div className='col-md-3'>
            <div
              className='roadmap-box mt-2'
              data-aos='fade-up'
              data-aos-delay='500'>
              <div>
                <h1>Q1.3</h1>
                {/* <TiTick className='tick' /> */}
                <ImRadioUnchecked />
              </div>
              <span>(2024)</span>

              <ul className='list-unstyled'>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Onboard Venture Capitalist{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Release NFT 2.0{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  Metavers Integration{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  PayPal Credit card payments{" "}
                </li>
                <li>
                  <img src={"assets/tickbag.svg"} alt='' className='mx-1' />
                  NFTs Staking{" "}
                </li>
              </ul>
            </div>
            {/* <img src={"assets/Vector.svg"} alt="" className="mb-4" />
                <div className="roadmap-box" data-aos='fade-down' data-aos-delay='300'>
                    <div>
                        <h1>Q4</h1>
                        <TiTick className="tick"/>
                    </div>
                    <span>Q3 2022</span>

                    <ul className="list-unstyled">
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    </ul>
                </div> */}
            {/* <div className="roadmap-box mt-2" data-aos='fade-up' data-aos-delay='500'>
                    <div>
                        <h1>Q5</h1>
                        <TiTick className="tick"/>
                    </div>
                    <span>Q3 2022</span>

                    <ul className="list-unstyled">
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    <li><img src={"assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop </li>
                    </ul>
                </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
