/** @format */

import React, { useState, useEffect } from "react";
import { FaXTwitter } from "react-icons/fa6";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  getAuth,
  onAuthStateChanged,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useEnsName } from "wagmi";
import CountUp from "react-countup";
import { firebase } from "../components/utils/Firebase";

const Airdrop = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState(null);
  const [rewardBtn, setRewardBtn] = useState(true);
  const [user, setUser] = useState(null);
  const [tweetURL, setTweetUrl] = useState("");
  const [errorText, setErrorText] = useState("");

  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const [twitter_id, setTwitterId] = useState("");
  const { open } = useWeb3Modal();
  function truncate(str, n) {
    return str.length > n ? str.slice(0, 4) + "..." + str.slice(-4) : str;
  }
  const handleClose = () => setShowModal(false);
  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);
  const handleClose3 = () => setShowModal3(false);
  const handleShow2 = () => {
    handleClose();
    setShowModal2(true);
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const getRef = () => {
    const ref = query.get("ref");
    return ref;
  };
  const ref = getRef();

  const [data, setData] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Link: "",
    btn2: "",
    btn2Fun: null,
  });
  const [data1, setData1] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Fun: null,
    btn2Fun: null,
  });
  const [data2, setData2] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Fun: null,
  });
  const auth = getAuth(firebase);
  const handleWAlletModal = () => {
    handleClose();
    handleClose1();
    handleClose2();
    handleClose3();
    open();
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authUser => {
      if (authUser) {
        setTwitterId(authUser?.uid);
        console.log("user Login Id", twitter_id);
        if (twitter_id !== "") {
          const userName = authUser?.displayName;
          axios
            .post("https://checkmateapi.onrender.com/api/linkx", {
              twitter_id,
              userName,
            })
            .then(res => {
              console.log(res?.data?.message);
              setScore(res?.data?.data);
              setLoading(false);
              if (ref !== "" && twitter_id !== "") {
                // referred();
              } else {
                console.log("ref not  get and tw id");
              }
            })
            .catch(error => {
              setLoading(false);
              console.log(error);
            });
          return;
        }

        setUser(authUser);
      } else {
        // User is signed out.
        setLoading(false);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth, twitter_id]);

  //  ========== Login ============

  const twitterLogin = () => {
    setLoading(true);
    const provider = new TwitterAuthProvider();
    signInWithPopup(auth, provider)
      .then(result => {
        const credentials = TwitterAuthProvider.credentialFromResult(result);

        const userLogin = result.user;
        console.log("user Login Id", userLogin);
        setUser(userLogin);
      })
      .catch(error => {
        const errorMessage = error.message;
        setLoading(false);
        console.log(errorMessage);

        const email = error.customData;
        console.log(email);

        const credential = TwitterAuthProvider.credentialFromError(error);
        console.log(credential);
      });
  };
  //  ========== LogOut ============

  const Logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch(error => {
        // An error happened.
      });
  };
  //  ========== shared ============
  const tell = () => {
    if (!tweetURL.trim()) {
      setErrorText("Please enter a URL");
      return;
    }
    setLoading(true);
    console.log(twitter_id);
    if (tweetURL !== "" && twitter_id !== "") {
      const tweetUrl = tweetURL;
      axios
        .put("https://checkmateapi.onrender.com/api/tell", {
          twitter_id,
          tweetUrl,
        })
        .then(res => {
          console.log(res?.data?.message);
          setScore(res?.data?.data);
          handleClose();
          handleClose1();
          handleClose2();
          handleClose3();

          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //  ========== follow ============

  const follow = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://checkmateapi.onrender.com/api/follow", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);

          setScore(res?.data?.data);
          handleClose();
          handleClose1();
          handleClose2();
          handleClose3();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //  ========== wallet ============

  const linkWallet = () => {
    setLoading(true);
    console.log(twitter_id);

    if (twitter_id) {
      const walletAddress = address;
      axios
        .put("https://checkmateapi.onrender.com/api/linkwallet", {
          twitter_id,
          walletAddress,
        })
        .then(res => {
          console.log(res?.data?.message);
          setScore(res?.data?.data);
          handleClose();
          handleClose1();
          handleClose2();
          handleClose3();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  //  ========== join ============

  const join = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://checkmateapi.onrender.com/api/join", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);
          setScore(res?.data?.data);
          handleClose();
          handleClose1();
          handleClose2();
          handleClose3();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //  ========== referred ============

  const referred = () => {
    setLoading(true);
    if (ref !== "" && twitter_id !== "") {
      console.log("ref =>", ref);
      console.log("tw =>", twitter_id);
      const referredBy = ref;
      axios
        .put("https://checkmateapi.onrender.com/api/referred", {
          twitter_id,
          referredBy,
        })
        .then(res => {
          console.log(res?.data.message);
          setScore(res?.data?.data);
          handleClose();
          handleClose1();
          handleClose2();
          handleClose3();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (ref !== "" && twitter_id !== "") {
      referred();
    } else {
      console.log("ref not  get and tw id");
    }
  }, [twitter_id]);

  return (
    <div className='container-fluid'>
      <div className='container'>
        <div className='row'>
          <div className='head-area mt-4'>
            <h1>Check Mate Quest</h1>
            <hr className='m-auto mt-3' />
          </div>
        </div>
        <div className='quest-main-box  m-auto p-5 position-relative mt-3'>
          {user?.uid ? (
            <div className='row'>
              <div className='col-lg-6 col-no1 border-e border-gray-200 '>
                <div className='profile-box m-auto '>
                  <div className='row  '>
                    <div className='col-5 m-auto'>
                      <div className='flex justify-center items-center m-auto '>
                        <img
                          src={user?.photoURL}
                          className=' img-fluid w-3/4 h-3/4 rounded-md'
                          alt=''
                        />
                      </div>
                    </div>
                    <div className='col-7 pt-2 '>
                      <h3>{user?.displayName}</h3>
                      <hr />
                      <h1 className='mt-3'>
                        <CountUp delay={2} start={0} end={score?.score} />
                      </h1>
                      <p>Points Earned</p>
                    </div>
                  </div>
                </div>
                <div className='flex mt-5 justify-between px-3 items-center w-75 sm:w-100 m-auto'>
                  <h5>Referred</h5>
                  <h5>{score?.referredCont - 1}</h5>
                </div>
                <div className='flex mt-3 justify-between px-3 items-center w-75 sm:w-100 m-auto'>
                  <h5>Wallet Address</h5>
                  <h5>
                    {isConnected
                      ? truncate(
                          `${ensName ? `${ensName} (${address})` : address} `,
                          8,
                        )
                      : "0x000"}
                  </h5>
                </div>
                <div className='text-center mt-5'>
                  <button onClick={Logout}>
                    Logout <FaXTwitter className='mx-2 fs-4' />
                  </button>
                </div>
              </div>
              <div className='col-lg-6 '>
                <div className='mx-5 box-inner'>
                  <h2>Check Mate Airdrop</h2>
                  <div className='mt-4'>
                    {/* ================  tell ================= */}
                    <div
                      className={`task-box ${score?.tell && "done"}`}
                      onClick={() => {
                        !score?.tell && setShowModal(true);
                        setData({
                          paraText:
                            "Unlocking Prosperity With High Returns. Empowering Users In The World Of Blockchain",
                          tweetText: `Hi Its me<span>@${user?.displayName}</span>, and i am a True Solider of <span>@checkmatebsc</span>.
                            <br />
                            <br />
                            i want to share a solid project with you guys name is  <span>checkmatebsc.xyz/airdrop</span> checkout and earn rewards like me
                           <br />
                           <br />
                          <span> Unlocking Prosperity With High Returns. Empowering Users In The World Of Blockchain</span>`,
                          btn1: `SHARE ON &nbsp;
                          <img src='assets/x.svg'alt='' width="20"  />`,
                          btn1Link: `http://twitter.com/share?text=Hi Its me @${user?.displayName},  and i am a True Solider of @checkmatebsc i want to share a solid project with you guys name is checkmatebsc.xyz/airdrop checkout and earn rewards like me &hashtags=checkmate,CKM,BNB,checkmatebsc,BSC,BTC,Binance,airdrop,trading,web3,ETH`,
                          btn2Fun: handleShow2,
                        });
                      }}>
                      <div className='row'>
                        <div className='col-3 m-auto'>
                          <div className='score-box m-auto'>
                            <img
                              src='assets/coin.svg'
                              className='img-fluid mx-2'
                              alt=''
                            />{" "}
                            <span>800</span>
                          </div>
                        </div>
                        <div className='col-2 m-auto'>
                          {score?.tell ? (
                            <img
                              src='assets/check.svg'
                              className='img-fluid '
                              alt=''
                            />
                          ) : (
                            <img
                              src='assets/uncheck.svg'
                              className='img-fluid '
                              alt=''
                            />
                          )}
                        </div>
                        <div className='col-7 m-auto'>
                          <h4>Tell the world!</h4>
                        </div>
                      </div>
                    </div>
                    {/* ================  follow ================= */}
                    <div
                      className={`task-box mt-3 ${score?.follow && "done"}`}
                      onClick={() => {
                        !score?.follow && setShowModal(true);
                        setData({
                          paraText: "Follow on Twitter",
                          tweetText: `If you are not following Check Mate, why are you here? <br/> <br/>  Follow <span>@checkmatebsc</span>`,
                          btn1: `Follow &nbsp;
                          <img src='assets/x.svg'alt='' width="20"  />`,
                          btn1Link: `https://twitter.com/intent/user?screen_name=checkmatebsc`,
                          btn2Fun: follow,
                        });
                      }}>
                      {" "}
                      <div className='row'>
                        <div className='col-3 m-auto'>
                          <div className='score-box m-auto'>
                            <img
                              src='assets/coin.svg'
                              className='img-fluid mx-2'
                              alt=''
                            />{" "}
                            <span>400</span>
                          </div>
                        </div>
                        <div className='col-2 m-auto'>
                          {score?.follow ? (
                            <img
                              src='assets/check.svg'
                              className='img-fluid '
                              alt=''
                            />
                          ) : (
                            <img
                              src='assets/uncheck.svg'
                              className='img-fluid '
                              alt=''
                            />
                          )}
                        </div>
                        <div className='col-7 m-auto'>
                          <h4 className='flex'>
                            Follow <FaXTwitter className=' mx-1 fs-6' />
                          </h4>
                        </div>
                      </div>
                    </div>
                    {/* ================  join ================= */}
                    <div
                      className={`task-box mt-3 ${score?.join && "done"}`}
                      onClick={() => {
                        !score?.join && setShowModal(true);
                        setData({
                          paraText: "Join on Telegram ",
                          tweetText: `Join <span>@checkmate_BSC</span> telegram community for the latest updates`,
                          btn1: `Join Telegram`,
                          btn1Link: `https://t.me/checkmate_BSC`,
                          btn2Fun: join,
                        });
                      }}>
                      <div className='row'>
                        <div className='col-3 m-auto'>
                          <div className='score-box m-auto'>
                            <img
                              src='assets/coin.svg'
                              className='img-fluid mx-2'
                              alt=''
                            />{" "}
                            <span>400</span>
                          </div>
                        </div>
                        <div className='col-2 m-auto'>
                          {score?.join ? (
                            <img
                              src='assets/check.svg'
                              className='img-fluid '
                              alt=''
                            />
                          ) : (
                            <img
                              src='assets/uncheck.svg'
                              className='img-fluid '
                              alt=''
                            />
                          )}
                        </div>
                        <div className='col-7 m-auto'>
                          <h4>Join TG community</h4>
                        </div>
                      </div>
                    </div>
                    {/* ================  referred ================= */}
                    <div
                      className={`task-box mt-3 ${score?.referred && ""}`}
                      onClick={() => {
                        setShowModal3(true);
                        setData2({
                          paraText: "Share with friends",
                          tweetText: `Use the unique referral link provided and earn extra points with <span>$CKM</span>! Don't miss out on this opportunity to boost your rewards. <br/> <br/><span>https://checkmatebsc.xyz/airdrop?ref=${twitter_id}</span>`,
                          btn1: `Referral link`,
                          btn1Link: `https://checkmatebsc.xyz/airdrop?ref=${twitter_id}`,
                        });
                      }}>
                      <div className='row'>
                        <div className='col-3 m-auto'>
                          <div className='score-box m-auto'>
                            <img
                              src='assets/coin.svg'
                              className='img-fluid mx-2'
                              alt=''
                            />{" "}
                            <span>200</span>
                          </div>
                        </div>
                        <div className='col-2 m-auto'>
                          {score?.referred ? (
                            <img
                              src='assets/check.svg'
                              className='img-fluid '
                              alt=''
                            />
                          ) : (
                            <img
                              src='assets/uncheck.svg'
                              className='img-fluid '
                              alt=''
                            />
                          )}
                        </div>
                        <div className='col-7 m-auto'>
                          <h4>Referred by friends?</h4>
                        </div>
                      </div>
                    </div>
                    {/* ================  wallet ================= */}
                    <div
                      className={`task-box mt-3 ${score?.linkWallet && "done"}`}
                      onClick={() => {
                        !score?.linkWallet && setShowModal1(true);
                        setData1({
                          paraText: "Link Wallet",
                          tweetText: `Please ensure that you connect the accurate NFT-holding wallet. Once linked, this cannot be altered. We also accept delegated wallets.`,
                          btn1: `CONNECT WALLET `,
                          btn1Fun: handleWAlletModal,
                          btn2Fun: linkWallet,
                        });
                      }}>
                      {" "}
                      <div className='row'>
                        <div className='col-3 m-auto'>
                          <div className='score-box m-auto'>
                            <img
                              src='assets/coin.svg'
                              className='img-fluid mx-2'
                              alt=''
                            />{" "}
                            <span>200</span>
                          </div>
                        </div>
                        <div className='col-2 m-auto'>
                          {score?.linkWallet ? (
                            <img
                              src='assets/check.svg'
                              className='img-fluid '
                              alt=''
                            />
                          ) : (
                            <img
                              src='assets/uncheck.svg'
                              className='img-fluid '
                              alt=''
                            />
                          )}
                        </div>
                        <div className='col-7 m-auto'>
                          <h4>Link your wallet</h4>
                        </div>
                      </div>
                    </div>
                    {/* ================  link profile ================= */}
                    <div className={`task-box mt-3 ${score?.linkX && "done"}`}>
                      <div className='row'>
                        <div className='col-3 m-auto'>
                          <div className='score-box m-auto'>
                            <img
                              src='assets/coin.svg'
                              className='img-fluid mx-2'
                              alt=''
                            />{" "}
                            <span>500</span>
                          </div>
                        </div>
                        <div className='col-2 m-auto'>
                          {score?.linkX ? (
                            <img
                              src='assets/check.svg'
                              className='img-fluid '
                              alt=''
                            />
                          ) : (
                            <img
                              src='assets/uncheck.svg'
                              className='img-fluid '
                              alt=''
                            />
                          )}
                        </div>
                        <div className='col-7 m-auto'>
                          <h4 className='complete flex align-center'>
                            Link your <FaXTwitter className=' mx-1 fs-6' />{" "}
                            profile
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mx-5 mt-3'>
                    <p className='alrt'>
                      Bots And Suspicious Actions Will Be Filtered Without Prior
                      Notice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='row'>
              <div className='col-lg-6 col-no1 border-e border-gray-200 flex justify-center items-center'>
                <img
                  src='assets/simple-icons_x.svg'
                  className=' img-fluid block'
                  alt=''
                />
              </div>
              <div className='col-lg-6 '>
                <div className='mx-5 box-inner'>
                  <h2>Check Mate Airdrop</h2>
                  <ul className='mt-5'>
                    <li>
                      <img
                        src='assets/checked.svg'
                        className='img-fluid mx-3'
                        alt=''
                      />
                      Login with X
                    </li>
                    <li>
                      {" "}
                      <img
                        src='assets/checked.svg'
                        className='img-fluid mx-3'
                        alt=''
                      />
                      COMPLETE QUESTS
                    </li>
                    <li>
                      {" "}
                      <img
                        src='assets/checked.svg'
                        className='img-fluid mx-3'
                        alt=''
                      />
                      COLLECT POINTS
                    </li>
                    <li>
                      {" "}
                      <img
                        src='assets/checked.svg'
                        className='img-fluid mx-3'
                        alt=''
                      />
                      CLAIM REWARD
                    </li>
                  </ul>

                  <div className='mx-5 mt-5'>
                    <button onClick={twitterLogin} disabled={loading}>
                      {loading ? (
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      ) : (
                        "Login with"
                      )}
                      &nbsp;
                      <FaXTwitter className='mx-2 fs-4' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <img
            src='assets/binance.svg'
            className='binance-icon position-absolute end-[-6rem] bottom-[-6rem]'
            alt=''
          />
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <div className='header-m'>
            <h2>QUEST</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <h4
            className='mt-4'
            dangerouslySetInnerHTML={{ __html: data?.paraText }}
          />
          {/* <h4 className='mt-4'>
            {" "}
            It may take a minute for us to find your post
          </h4> */}
          <div className='tweet mt-4 mb-3'>
            <p dangerouslySetInnerHTML={{ __html: data?.tweetText }} />
            {/* <p>
              Hi, my name is <span>@mr_david</span>, and I’m a Player  at{" "}
              <span>@checkmatebsc</span>. <br />
              <br />I promise to always work hard to take care of Earnings{" "}
              <span>#CHECKMATEPOINTS</span> whenever I can. <br />
              <br />
              <span>#CKM #CheckMateBsc #BNB</span>
            </p> */}
          </div>
          <div className='flex justify-center gap-3'>
            <a href={data?.btn1Link} target='_blank' rel='noopener noreferrer'>
              <button
                onClick={() => setRewardBtn(false)}
                className='mb-3 btn m-auto block fills'
                dangerouslySetInnerHTML={{
                  __html: loading
                    ? `<div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
                </div>
                `
                    : data?.btn1,
                }}
              />
            </a>
            <button
              disabled={rewardBtn}
              onClick={data?.btn2Fun}
              className='mb-3 outline block btn'>
              COLLECT POINTS
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showModal1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <div className='header-m'>
            <h2>QUEST</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p
            className='mt-4'
            dangerouslySetInnerHTML={{ __html: data1?.paraText }}
          />
          <div className='tweet mt-3 mb-3'>
            <p dangerouslySetInnerHTML={{ __html: data1?.tweetText }} />
          </div>
          {!isConnected && (
            <button
              onClick={() => {
                data1?.btn1Fun();
                setRewardBtn(false);
              }}
              className='mb-3 fills '
              dangerouslySetInnerHTML={{
                __html: loading
                  ? `<div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              `
                  : data1?.btn1,
              }}
            />
          )}
          {isConnected && (
            <button onClick={data1?.btn2Fun} className='mb-3 outline btn'>
              CLAIM REWARD
            </button>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showModal2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <div className='header-m'>
            <h2 className='fs-4'>Reward</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <h4 className='mt-3'>Submit Tweet Url</h4>
          <p className='mt-201 mb-3 text-center'>
            Submit tweet url and get points
          </p>
          <div className='tweet mt-3 mb-3'>
            <input
              onChange={e => setTweetUrl(e.target.value)}
              type='url'
              name='url'
              required=''
              className='input-sec'
              placeholder='paste Url here...'
            />
          </div>
          <p className='text-center mb-3'>{errorText ? errorText : null}</p>
          <div className='flex justify-center items-center'>
            <button
              onClick={tell}
              disabled={rewardBtn}
              className='mb-3 fills btn block m-auto'>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showModal3} onHide={handleClose3} centered>
        <Modal.Header closeButton>
          <div className='header-m'>
            <h2 className='fs-5'>REFERRED</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <h4
            className='mt-4'
            dangerouslySetInnerHTML={{ __html: data2?.paraText }}
          />
          <div className='tweet mt-3 mb-3'>
            <p dangerouslySetInnerHTML={{ __html: data2?.tweetText }} />
          </div>
          <a href={data2?.btn1Link} target='_blank' rel='noopener noreferrer'>
            <button
              className='mb-3 fills m-auto'
              dangerouslySetInnerHTML={{
                __html: loading
                  ? `<div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              `
                  : data2?.btn1,
              }}
            />
          </a>
          {/* {score?.referredCont > 1 && (
            <button onClick={referred} className='mb-3 outline btn'>
              CLAIM REWARD
            </button>
          )} */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Airdrop;
