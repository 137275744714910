/** @format */

import React from "react";
import {
  Hero,
  Roadmap,
  Features,
  About,
  Faqs,
  Tokenomics,
  Partners,
} from "../components/elements";
import MarketPlace from "../components/elements/MarketPlace";

const Home = () => {
  return (
    <React.Fragment>
      <Hero />
      <About />
      <Features />
      <MarketPlace />
      <Roadmap />
      <Tokenomics />
      <Partners />
      <Faqs />
    </React.Fragment>
  );
};

export default Home;
