/** @format */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCwPOoSLrJ076rPHHL2bXWcPOXs7zuYEcQ",
  authDomain: "checkmatebsc-873ac.firebaseapp.com",
  projectId: "checkmatebsc-873ac",
  storageBucket: "checkmatebsc-873ac.appspot.com",
  messagingSenderId: "741434293008",
  appId: "1:741434293008:web:d332282457aa7f3936c395",
  measurementId: "G-EEYNKK8FCN"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);