/** @format */

import React from "react";
import Tilt from "react-parallax-tilt";
import { FaBook } from "react-icons/fa";
const Hero = () => {
  return (
    <div className='container-fluid hero pt-5'>
      <div className='container'>
        <div className='row animate-appear-slide-up'>
          <div className='col-md-6 m-auto transition-colors '>
            <h1>Checkmate on BSC</h1>
            <p>
              Unlocking Prosperity with High Returns. Empowering Users in the
              World of Blockchain
            </p>
            <div className='btn-sec'>
              <a
                href='https://check-mate-bsc.gitbook.io/checkmate/'
                title='Gitbook'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <button>
                  <FaBook />
                  &nbsp; Whitepaper
                </button>
              </a>
            </div>
          </div>
          <div className='col-md-6 m-auto'>
            <Tilt
              className='parallax-effect-img'
              tiltMaxAngleX={40}
              tiltMaxAngleY={40}
              perspective={800}
              transitionSpeed={1500}
              scale={1.1}
              gyroscope={true}>
              <img
                src='assets/hero-bg.png'
                className='w-75 m-auto'
                alt='hero bg'
              />
            </Tilt>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
