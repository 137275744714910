/** @format */

import React from "react";
import { FaRegCopy } from "react-icons/fa";

const Features = () => {
  return (
    <div className='container-fluid mt-5 '>
      <div className='container'>
        <div className='head-area'>
          <h1>CKM Features</h1>
          <p className="sm:w-100 w-96 m-auto">
            {" "}
            The $CKM token is the main currency of the CheckMate project, thus
            having many transactional features.
          </p>
          <hr className='m-auto mt-3' />
        </div>
        <div className='row mt-5'>
          <div className='col-md-3'>
          <div>
              <div
                className='card tokken-cards m-2 animate-appear-slide-up'
                data-aos='fade-up'
                data-aos-delay='100'>
                <div className='card-body '>
                  <img
                    src={"/assets/point.svg"}
                    alt='' className="w-25 block m-auto"
                  />
                  <h1>Earn Points</h1>
                  <p>
                    The power points in the game are FIRE tokens that can
                    easily be converted into $CKM tokens.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
          <div>
              <div
                className='card tokken-cards m-2 animate-appear-slide-up'
                data-aos='fade-up'
                data-aos-delay='200'>
                <div className='card-body '>
                  <img
                    src={"/assets/gov.svg"}
                    alt='' className="w-25 block m-auto"

                  />
                  <h1>Governance</h1>
                  <p>
                    The $CKM token enables users to participate on the
                    governance platform, and become the part of the change they
                    seek.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
          <div>
              {" "}
              <div
                className='card tokken-cards m-2 animate-appear-slide-up'
                data-aos='fade-up'
                data-aos-delay='500'>
                <div className='card-body '>
                  <img
                    src={"/assets/stake.svg"}
                    alt='' className="w-25 block m-auto"

                  />
                  <h1>Staking</h1>
                  <p>
                    There are multiple options, such as, NFT staking, Staking. Users can grow their assets exponentially on
                    CheckMate.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
          <div>
              <div
                className='card tokken-cards m-2 animate-appear-slide-up'
                data-aos='fade-up'
                data-aos-delay='600'>
                <div className='card-body '>
                  <img
                    src={"/assets/exchange.svg"}
                    alt='' className="w-25 block m-auto"

                  />
                  <h1>Bridge</h1>
                  <p>
                  Now trade your tokens on all blockchains without any hassle.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='col-md-3'>
          <div>
              <div className='card tokken-cards'>
                <div className='card-body '>
                  <img src='assets/Staking.svg' alt='' />
                  <h1></h1>
                  <p>
                  
                  </p>
                </div>
              </div>
            </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Features;
