/** @format */

import React, { useState, useEffect } from "react";
import { CiCoinInsert } from "react-icons/ci";
import { Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

const Header = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const scrollProgress = () => {
      const scrollPx = document.documentElement.scrollTop;
      const winHeightPx =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = `${(scrollPx / winHeightPx) * 100}`;
      setProgress(scrolled);
    };

    window.addEventListener("scroll", scrollProgress);

    return () => {
      window.removeEventListener("scroll", scrollProgress);
    };
  }, []);
  return (
    <>
      <header className='sticky top-0 z-50'>
        <nav className='navbar navbar-expand-lg relative z-50'>
          <div className='container'>
            <Link
              className='navbar-brand flex justify-center items-center'
              to='/'>
              <img
                src='assets/logo-cr.png'
                width={50}
                className='mx-2'
                alt=''
              />{" "}
              Check Mate
            </Link>
            <button
              className='navbar-toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarText'
              aria-controls='navbarText'
              aria-expanded='false'
              aria-label='Toggle navigation'>
              <span className='navbar-toggler-icon' />
            </button>
            <div className='collapse navbar-collapse' id='navbarText'>
              <ul className='navbar-nav me-auto mb-2 mb-lg-0'></ul>
              <span className='navbar-text'>
                <ul className='navbar-nav me-auto mb-2 mb-lg-0 animate-appear-expand-right'>
                  <li className='nav-item'>
                    <a className='nav-link active interactive-text' href='/'>
                      Home
                    </a>
                  </li>
                  <li className='nav-item '>
                    <a
                      className='nav-link interactive-text'
                      href='https://stake.checkmatebsc.xyz/'
                      target='_blank'
                      rel='noreferrer'>
                      Stake
                    </a>
                  </li>
                  <li className='nav-item '>
                    <a
                      className='nav-link interactive-text'
                      href='https://vote.checkmatebsc.xyz/'
                      target='_blank'
                      rel='noreferrer'>
                      Gov
                    </a>
                  </li>
                  <li className='nav-item '>
                    <Link className='nav-link interactive-text' to='/bridge'>
                      Bridge
                    </Link>
                  </li>
                  <li className='nav-item mx-5'>
                    <Link to='/airdrop'>
                      <button className='nav-link'>
                        <CiCoinInsert className='fs-4 mx-2' /> Airdrop (Live)
                      </button>
                    </Link>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </nav>
      </header>

      <LoadingBar
        color='#d5a419'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
    </>
  );
};

export default Header;
