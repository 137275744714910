/** @format */

import React from "react";

const MarketPlace = () => {
  const nftsArray = [
    { imgUrl: "assets/1.png" },
    { imgUrl: "assets/2.png" },
    { imgUrl: "assets/3.png" },
    { imgUrl: "assets/4.png" },
    { imgUrl: "assets/5.png" },
    { imgUrl: "assets/6.png" },
    { imgUrl: "assets/7.png" },
    { imgUrl: "assets/8.png" },
    { imgUrl: "assets/9.png" },
  ];
  return (
    <>
      <div className='container-fluid Schedule-bg pt-5 pb-5 mb-5'>
        <div className='container'>
          <div className='row head-area'>
            <div className='col-md-12 '>
              <h1 data-aos='fade-down' data-aos-delay='300'>
                - shop -
              </h1>
              <p data-aos='fade-up' data-aos-delay='500'>
                Marketplace Items
              </p>
              <hr className='m-auto mt-3' />
            </div>
          </div>
          <div className='row mt-3'>
            {nftsArray.map((v, i) => {
              return (
                <div className='col-md-3 mt-3' key={i}>
                  <div className='nft-card m-auto'>
                    <div className='card-body'>
                      <img src={v.imgUrl} className='w-100' alt='' />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketPlace;
