/** @format */

import { Pie, G2 } from "@ant-design/plots";

const Chart = () => {
  const G = G2.getEngine("canvas");
  const data = [
    {
      type: "Private Sale",
      value: 5,
    },
    {
      type: "Fairlaunch",
      value: 30,
    },
    {
      type: "LP",
      value: 16,
    },
    {
      type: "Staking rewards",
      value: 14,
    },
    {
      type: "P2E rewards ",
      value: 5,
    },
    {
      type: "Airdrop rewards",
      value: 3.5,
    },
    {
      type: "CEX Listing",
      value: 10,
    },
    {
      type: "insurance Funds",
      value: 5,
    },
    {
      type: "Marketing ",
      value: 10,
    },
    {
      type: "Team",
      value: 2,
    },
    {
      type: "Development Funds ",
      value: 3,
    },
  ];
  const cfg = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.75,
    legend: false,
    label: {
      type: "spider",
      labelHeight: 40,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: "circle",
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 50,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 10,
            y: 8,
            text: `${data.type}`,
            fill: mappingData.color,
          },
        });
        // group.addShape({
        //   type: "text",
        //   attrs: {
        //     x: 0,
        //     y: 25,
        //     text: `${data.value}个 ${data.percent * 100}%`,
        //     fill: "#fff",
        //     fontWeight: 700,
        //   },
        // });
        return group;
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };
  const config = cfg;
  return <Pie {...config} />;
};

export default Chart;
