/** @format */

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.scss";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Base from "./components/layout/Base";
import Home from "./pages/Home";
import Airdrop from "./pages/Airdrop";
import NotFound from "./pages/NotFound";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc } from "wagmi/chains";
import Alpine from "alpinejs";
import Stake from "./pages/Stake";
import Bridge from "./pages/Bridge";
import Gov from "./pages/Gov";
const App = () => {
  const chains = [bsc];
  const projectId = "1c200fb2c2e182bbda9170cd52450702";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);
  useEffect(() => {
    window.Alpine = Alpine;

    Alpine.start();
  }, []);
  return (
    <React.Fragment>
      <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
          <Base>
            <Routes>
              <Route index path='/' element={<Home />} />
              <Route path='/airdrop' element={<Airdrop />} />
              {/* <Route path='/stake' element={<Stake />} /> */}
              <Route path='/bridge' element={<Bridge />} />
              {/* <Route path='/gov' element={<Gov />} /> */}
              <Route path='/*' element={<NotFound />} />
            </Routes>
          </Base>
        </BrowserRouter>
      </WagmiConfig>
      <div className=' z-3  position-absolute'>
        <Web3Modal
          projectId={projectId}
          ethereumClient={ethereumClient}
          themeMode='dark'
          themeVariables={{
            "--w3m-background": "#d5a419",
            "--w3m-background-color": "#d5a419",
            "--w3m-text-primary": "#ffffff",
            "--w3m-logo-image-url":
              "https://pbs.twimg.com/profile_images/1718232200678645760/ElU4v1Nt_400x400.png",
          }}
          defaultChain={56}
        />
      </div>{" "}
    </React.Fragment>
  );
};

export default App;
