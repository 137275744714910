/** @format */

import React from "react";
import { FaXTwitter } from "react-icons/fa6";
import { LiaTelegramPlane, LiaInstagram } from "react-icons/lia";
import { FaDiscord, FaFacebookF } from "react-icons/fa";
const Footer = () => {
  return (
    <footer>
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='social-icons flex gap-3 w-100 justify-center'>
              <a href='https://twitter.com/checkmatebsc' target='_blank' rel="noreferrer" >
                <FaXTwitter />
              </a>
              <a href='https://t.me/checkmate_BSC' target='_blank' rel="noreferrer">
                <LiaTelegramPlane /> 
              </a>
              {/* <a href='#!' target='_blank' rel="noreferrer">
                <FaDiscord />
              </a>
              <a href='#!' target='_blank' rel="noreferrer">
                <FaFacebookF />
              </a> */}
              <a href='https://www.instagram.com/checkmatebsc/' target='_blank' rel="noreferrer">
                <LiaInstagram />
              </a>
            </div>
            <p>&copy; 2024. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
